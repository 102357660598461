/* Global styles */
body {
    margin: 0;
    padding: 0;
    font-family: monospace;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #333333;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

* {
    box-sizing: border-box;
}

/* App styles */
.App {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.grid-plotter-main-window {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.top-row,
.bottom-row {
    display: flex;
    flex-direction: row;
}

.workspace {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
}

.control-plot {
    width: 100%;
    height: 100%;
    background-color: #444444;
    position: relative;
}

.center-line {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.2);
}

.center-line.vertical {
    left: 50%;
    top: 0;
    bottom: 0;
    width: 1px;
}

.center-line.horizontal {
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
}

.crosshair-vertical,
.crosshair-horizontal {
    position: absolute;
    background-color: rgb(211, 211, 211);
    pointer-events: none;
}

.crosshair-vertical {
    width: 1px;
    height: 100%;
    top: 0;
}

.crosshair-horizontal {
    width: 100%;
    height: 1px;
    left: 0;
}

.rect-selector {
    position: absolute;
    border: 1px solid #00FF00;
    pointer-events: none;
}

/* GRIDPLOTTER END */

/* PointsManager styles */
.points-manager {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

input, textarea {
    user-select: text;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
}

.left-panel {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.center-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.export-container {
    margin-top: 20px;
}

.plot-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* ControlPlot styles */
.control-plot-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
}

.plot-label {
    margin-bottom: 10px;
    font-weight: bold;
    text-align: center;
}

.control-plot {
    width: 512px;
    height: 512px;
    border: 1px solid white;
    background-color: #444444;
    position: relative;
}

/* ControlPlotInput styles */
.control-plot-input {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.lists {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.list {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    padding: 5px;
    cursor: pointer;
    background-color: #555555;
    border: 1px solid white;
}

.list:hover {
    background-color: #666666;
}

.list-name {
    margin-right: 10px;
    text-align: center;
}

.list input[type="color"] {
    margin-left: auto;
}

.list.active {
    font-weight: bold;
    background-color: #777777;
}

.list-type-buttons {
    display: flex;
    margin-right: 10px;
}

.list-type-button {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #444444;
    border: 1px solid #666666;
    color: white;
    font-weight: bold;
    cursor: pointer;
    margin-right: 4px;
}

.list-type-button:hover {
    background-color: #555555;
}

.list-type-button.active {
    background-color: #777777;
    border-color: #999999;
}

.visibility-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    background-color: #444444;
    border: 1px solid #666666;
    border-radius: 3px;
    cursor: pointer;
}

.visibility-toggle:hover {
    background-color: #555555;
}

.material-symbols-outlined {
    font-size: 20px;
    color: white;
}

.cursor-options {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 20px;
}

.cursor-options input[type="color"] {
    width: 30px;
    height: 30px;
    padding: 0;
    border: none;
    border-radius: 50%;
    background: none;
    cursor: pointer;
}

.cursor-options input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
}

.cursor-options input[type="color"]::-webkit-color-swatch {
    border: none;
    border-radius: 50%;
}

.resize-handle {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: white;
    border: 1px solid black;
}

.resize-handle:hover {
    background-color: #ddd;
}

.resize-handle-nw {
    cursor: nw-resize;
    top: -5px;
    left: -5px;
}

.resize-handle-ne {
    cursor: ne-resize;
    top: -5px;
    right: -5px;
}

.resize-handle-sw {
    cursor: sw-resize;
    bottom: -5px;
    left: -5px;
}

.resize-handle-se {
    cursor: se-resize;
    bottom: -5px;
    right: -5px;
}

.resize-handle-n {
    cursor: n-resize;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
}

.resize-handle-e {
    cursor: e-resize;
    right: -5px;
    top: 50%;
    transform: translateY(-50%);
}

.resize-handle-s {
    cursor: s-resize;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
}

.resize-handle-w {
    cursor: w-resize;
    left: -5px;
    top: 50%;
    transform: translateY(-50%);
}

.resizing {
    user-select: none;
}

/* Button styles */
button {
    cursor: pointer;
    padding: 5px 10px;
    background-color: #555;
    color: white;
    border: 1px solid #777;
    border-radius: 3px;
}

button:hover {
    background-color: #666;
}

/* Point styles */
.point {
    width: 10px;
    height: 10px;
    position: absolute;
    border: 1px solid white;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}

.point.selected {
    width: 14px;
    height: 14px;
    border: 2px solid white;
    z-index: 10;
}

/* Point name styles */
.point-name {
    position: absolute;
    transform: translateX(-50%);
    font-size: 12px;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 2px 4px;
    border-radius: 3px;
}

/* GuidingLines styles */
.guiding-lines {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.vertical-line {
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: #999999;
}

.horizontal-line {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #999999;
}

/* Crosshair styles */
.crosshair-vertical,
.crosshair-horizontal {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.5);
    pointer-events: none;
}

.crosshair-vertical {
    width: 1px;
    height: 100%;
}

.crosshair-horizontal {
    width: 100%;
    height: 1px;
}

/* PointNamer styles */
.point-namer {
    display: flex;
    gap: 10px;
    margin-top: 28px;
    margin-bottom: 10px;
}

.point-namer input {
    flex-grow: 1;
    padding: 5px;
    background-color: #3b3b3b00;
    color: white;
    border: 1px solid #777;
    border-radius: 3px;
}

.point-name {
    position: absolute;
    transform: translate(-50%, -100%);
    background-color: rgba(0, 0, 0, 0.476);
    color: white;
    padding: 2px 4px;
    border-radius: 3px;
    font-size: 12px;
    white-space: nowrap;
    pointer-events: none;
    z-index: 11;
}

/* Exporter styles */
.exporter {
    text-align: center;
}

/* ReferenceManager styles */
.reference-manager {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.data-manager {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
}

.import-button,
.export-button {
    cursor: pointer;
    padding: 5px 10px;
    background-color: #555;
    color: white;
    border: 1px solid #777;
    border-radius: 3px;
}

.import-button:hover,
.export-button:hover {
    background-color: #666;
}

.toggle-container {
    display: flex;
    align-items: center;
    gap: 5px;
}

.toggle-button {
    cursor: pointer;
    padding: 5px 10px;
    background-color: #555;
    color: white;
    border: 1px solid #777;
    border-radius: 3px;
}

.toggle-button:hover {
    background-color: #666;
}

.toggle-button.active {
    background-color: #777;
}

.reference-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    pointer-events: none;
}